import axios from '@axios'
import admins from '@mocks/mockAdmins'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAdmins(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/users`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addOrganisationUser(ctx, params) {
      let body = {
        profile: {
            first_name: params.firstName,
            last_name: params.lastName,
            email: params.email,
            phone_number: params.phone
        },
        resource: {
          role: params.role
        }

      }

      return new Promise((resolve, reject) => {
        axios
          .post(`/users`, body)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateAdmin(ctx, admin) {

    },
    fetchAdmin(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/users/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    //DEV Call for Mock
    fetchAdminsMock(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        resolve({
          results: admins
        })
      })
    },

  },
}
