<template>
  <b-modal
    ref="payment-modal-reservation"
    @submit="submit"
    size="lg"
    :title="'Paiement pour réservation - #' + reservation.reservation_number"
    hide-footer
  >
    <div class="my-1">
      Total des paiements effectués: <strong>{{ totalPayment }}</strong>
    </div>

    <b-form>
      <b-row v-for="(payment, i) in payments" :key="i">
        <b-col md="4">
          <b-form-group label="Date" label-for="Date">
            <b-form-input
              v-model="payment.date"
              class="mb-1"
              type="date"
              max="2100-01-01"
              locale="fr"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label="Type de paiement" label-for="Type de paiement">
            <v-select
              ref="payment-type"
              v-model="payment.type"
              :options="paymentTypes"
              :selectable="(option) => !option.value.includes('select_value')"
              label="text"
              :reduce="(text) => text.value"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label="Montant" label-for="Montant">
            <b-form-input
              :value="getAmount(payment)"
              @change="setAmount(payment, $event)"
            />
          </b-form-group>
        </b-col>
        <b-col md="1" class="my-auto px-0">
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-danger"
            class="btn-icon rounded-circle"
            @click="removePayment(i)"
          >
            <feather-icon icon="Trash2Icon" />
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="11"></b-col>
        <b-col md="1" class="my-auto px-0">
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-primary"
            class="btn-icon rounded-circle"
            @click="addPayment"
          >
            <feather-icon icon="PlusIcon" />
          </b-button>
        </b-col>
      </b-row>
    </b-form>

    <hr />

    <div class="d-flex justify-content-end">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="outline-secondary"
        size="md"
        @click="cancel"
        class="mr-1"
      >
        Annuler
      </b-button>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        size="md"
        @click="submit"
      >
        Enregistrer
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import dayjs from "dayjs";
import BCardCode from "@core/components/b-card-code";
import {
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormInput,
  BFormGroup,
  BCardText,
  BRow,
  BCol,
  BInputGroup,
  BFormCheckbox,
  BInputGroupPrepend,
  BFormRadio,
  BFormTextarea,
  BTabs,
  BFormFile,
  BFormCheckboxGroup,
  BTab,
  BFormInvalidFeedback,
  BFormRadioGroup,
  BFormDatepicker,
} from "bootstrap-vue";
import { localize, ValidationProvider, ValidationObserver } from "vee-validate";
import fr from "vee-validate/dist/locale/fr.json";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

localize("fr", fr);

export default {
  name: "ReservationPaymentModal",
  components: {
    ValidationProvider,
    ValidationObserver,
    BCardCode,
    BButton,
    BModal,
    BForm,
    BFormInput,
    BCardText,
    BFormGroup,
    vSelect,
    BRow,
    BFormRadioGroup,
    BCol,
    BInputGroup,
    BFormCheckbox,
    BFormCheckboxGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BTabs,
    BFormFile,
    BTab,
    BFormInvalidFeedback,
    ToastificationContent,
    BFormRadio,
    BFormDatepicker,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: ["reservation"],
  data() {
    return {
      payments: [],
    };
  },
  created() {
    this.reservation.reservation_payments.forEach(p => {
      let date = this.getDate(p);

      this.payments.push({
        id: p.id,
        amount: p.amount,
        type: p.type,
        date: date
      });
    });

    this.payments.sort((a, b) => new Date(a.date) - new Date(b.date));
  },
  computed: {
    reservationPayment: {
      get() {
        return this.reservation.payment / 100;
      },
      set(newValue) {
        this.payment = newValue * 100;
      },
    },
    totalPayment() {
      let total = 0.0;
      this.payments.forEach((x) => (total += x.amount));
      return (total / 100).toFixed(2) + " $";
    },
    paymentTypes() {
      return [
        { value: "stripe", text: "Stripe" },
        { value: "moneris", text: "Moneris" },
        { value: "manual", text: "Saisie manuelle" },
      ];
    },
  },
  methods: {
    submit() {
      this.reservation.payment = this.payment;
      this.$emit("submit", this.reservation, this.payments);
    },
    cancel() {
      this.$refs["payment-modal-reservation"].hide();
    },
    getDate(payment) {
      if (payment.date == null) {
        return "";
      }

      let date = payment.date;
      if (Array.isArray(date)) {
        date = dayjs(date.join("-"));
      } else {
       return date;
      }
      return date;
    },

    getAmount(payment) {
      let value = 0.0;
      if (payment.amount) {
        value = payment.amount / 100;
      }
      return value.toFixed(2);
    },
    setAmount(payment, newValue) {
      payment.amount = parseInt(parseFloat(newValue) * 100);
    },
    addPayment() {
      this.payments.push({
        date: dayjs().format("YYYY-MM-DD"),
        type: "",
        amount: 0.0,
      });
    },
    removePayment(index) {
      this.payments.splice(index, 1);
    },
  },
};
</script>
