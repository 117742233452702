<template>
  <b-modal
    id="group-advanced-information-modal"
    :title="t('group.modal.advanced_info.modal_title')"
    :ok-title="t('button.save')"
    :cancel-title="t('button.cancel')"
    cancel-variant="outline-secondary"
    size="lg"
    @ok="submitFormData"
    @cancel="resetForm"
    @show="fillDataIfProvided"
    no-close-on-backdrop
  >
    <b-form>
      <b-row>
        <b-col cols="12" class="mb-2">
          <h1 class="section-label mx-0 mt-2">{{ t('group.modal.advanced_info.modal_common_section') }}</h1>
        </b-col>
        <b-col md="6">
          <b-form-group :label="t('group.advanced_info.link')" label-for="link">
            <b-form-input
              :placeholder="t('group.modal.advanced_info.url_example')"
              v-model="formData.link"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            :label="t('group.advanced_info.description')"
            label-for="description"
            description="Limite de 2000 caractères">
            <b-form-textarea
              rows="4"
              size="sm"
              maxlength="2000"
              :placeholder="t('group.modal.advanced_info.description_example')"
              v-model="formData.description"
            >
            </b-form-textarea>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <b-form-group
            :label="t('group.advanced_info.extra_information')"
            label-for="extra-information"
            description="Limite de 1024 caractères">
            <b-form-textarea
              size="sm"
              maxlength="1024"
              v-model="formData.extraInformation"></b-form-textarea>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            :label="t('group.advanced_info.requirements')"
            label-for="requirements"
            description="Limite de 1024 caractères">
            <b-form-textarea
              size="sm"
              maxlength="1024"
              placeholder=""
              v-model="formData.requirements">
            </b-form-textarea>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <b-form-group :label="t('group.advanced_info.inclusions')" label-for="inclusions">
            <b-form-tags
              :placeholder="t('button.add_tag_placeholder')"
              :addButtonText="t('button.add')"
              v-model="formData.inclusions"
              input-id="tags-basic"
              class="mb-2"
              separator="•;"
            >
            </b-form-tags>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group :label="t('group.advanced_info.exclusions')" label-for="exclusions">
            <b-form-tags
              :placeholder="t('button.add_tag_placeholder')"
              :addButtonText="t('button.add')"
              v-model="formData.exclusions"
              input-id="tags-basic"
              class="mb-2"
              separator="•;"
            >
            </b-form-tags>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="mb-2">
          <h1 class="section-label mx-0 mt-2">{{ t('group.modal.advanced_info.image_section') }}</h1>
        </b-col>
        <b-col md="6">
          <b-form-file
            ref="file-upload"
            v-model="images.file1"
            :state="Boolean((images.file1 || formData.images))"
            placeholder="Veuillez sélectionner une image..."
            drop-placeholder="Déposer l'image ici..."
            browse-text="Parcourir"
            @change="uploadImageToGroup"
          ></b-form-file>
          <small class="form-text text-muted">Poids maximal de l'image: 5 Mo</small>
        </b-col>
        <b-col md="6">
        <b-overlay :show="show" opacity="0.85" rounded="sm">
          <b-img :src="formData.images" fluid ></b-img>
          <!-- <input type="file" accept="image/*" @input="uploadImage2($event)" id="file-input"> -->
          </b-overlay>
        </b-col>
      </b-row>
    </b-form>
  </b-modal>
</template>

<script>
import {
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupPrepend,
  BFormTags,
  BFormTextarea,
  BFormFile,
  BImg,
  BOverlay,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import Ripple from 'vue-ripple-directive';
import {useLocalisation} from "@/shared/composables/use-localisation";
import {useValidatorsWithAutoFormDataCleanUp} from "@/shared/composables/use-validators";
import {reactive, ref} from "@vue/composition-api/dist/vue-composition-api";
import {useGroupApi} from "@/modules/group/composables/use-group-api";
import {useToastNotification} from "@/shared/composables/use-toast-notifications";
import {useApplicationContext} from "@/shared/composables/use-application-context";
import Vue from 'vue'

export default {
  name: 'group-advanced-information-modal',
  components: {
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BOverlay,
    vSelect,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormTags,
    BFormFile,
    BImg
  },
  directives: {
    Ripple,
  },
  props: {
    groupId: {
      type: String,
      required: true,
    },
    information: {
      type: Object,
      required: false,
      default: () => {
        return {};
      }
    },
    action: {
      type: String,
      required: true,
    }
  },
  setup(props, {emit}) {
    const {updateGroupAdvancedInformation, uploadGroupMedia} = useGroupApi();
    const {forceUpdate} = useApplicationContext();
    const {t} = useLocalisation();
    const {displaySuccessMessage, displayErrorMessage} = useToastNotification();


    let formData = reactive({
      link: '',
    });
    let show = ref(false)
    let images = reactive({})
    const fillDataIfProvided = () => {
      if (props.action === 'update') {
        Object.assign(formData, props.information);
        forceUpdate();
      }
    };

    const submitFormData = async () => {
      try {
        if(formData.link) {
          formData.link = (formData.link.indexOf('://') === -1) ? 'http://' + formData.link : formData.link;
        }
        await updateGroupAdvancedInformation(props.groupId, formData);
        displaySuccessMessage('Les informations avancées du groupe ont été sauvegardées avec succès');
        emit('monica-group-saved');
      } catch {
        displayErrorMessage("La sauvegarde d'information du groupe a échoué");
      }
    };


    const uploadImageToGroup = async (event) => {
      let file = event.target.files[0];

      if (file) {
        show.value = true;

        let data = new FormData();
        data.append('name', file.name);
        data.append('type', 'image');
        data.append('file', event.target.files[0]);

        try {
          let response = await uploadGroupMedia(props.groupId, data)
          let imageUrl = ''

          if (Object.hasOwn(response.data, 'media_url')) {
              imageUrl = response.data.media_url
            } else {
              imageUrl = response.headers.location
            }
          Vue.set(formData, 'images', imageUrl)
          forceUpdate();
          show.value = false;
        } catch (e) {
          console.error(e)
          displayErrorMessage("Le téléversement de l'image a échoué.");
          forceUpdate();
          show.value = false;
          images.file1 = null
          this.$refs['file-upload'].reset()
        }
      }
    }

    const {resetForm} = useValidatorsWithAutoFormDataCleanUp(formData);

    return {
      formData,
      t,
      resetForm,
      submitFormData,
      fillDataIfProvided,
      images,
      uploadImageToGroup,
      show
    };
  }
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
